import {
  CHECK_PASSWORD,
  EDIT,
  GET_ALL_COMMUNES,
  GET_ALL_CONSULATES,
  LOADING,
  LOGIN,
  LOGIN_FAILED,
  LOGOUT,
  REGISTER,
  SEARCHED_COMMUNES,
  SHOW_MODAL_FORM_SENDED_SUCCESSFULLY,
} from '../../utils/constants/reduxConstants';

const initialState = {
  user: {},
  token: null,
  loading: false,
  showModal: false,
  paidToken: false,
  searchedCommunes: [],
  allCommunes: [],
  allConsulates: [],
  error: null,
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case REGISTER:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        paidToken: action.payload.paidToken,
        error: action.payload.error,
      };
    case LOGIN:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        paidToken: action.payload.paidToken,
        error: action.payload.error,
      };
    case EDIT:
      return {
        ...state,
        user: action.payload.user,
      };
    case CHECK_PASSWORD:
      return {
        ...state,
        paidToken: action.payload,
      };
    case LOGIN_FAILED: {
      return {
        ...state,
        error: action.payload.error,
        token: null,
      };
    }
    case LOGOUT:
      return {
        ...state,
      };
    case SEARCHED_COMMUNES:
      return {
        ...state,
        searchedCommunes: action.payload.comunas,
        loading: action.payload.loading,
        error: action.payload.error,
      };
    case GET_ALL_COMMUNES:
      return {
        ...state,
        allCommunes: action.payload,
      };
    case GET_ALL_CONSULATES:
      return {
        ...state,
        allConsulates: action.payload,
      };
    case SHOW_MODAL_FORM_SENDED_SUCCESSFULLY:
      return {
        ...state,
        showModal: action.payload.showModal,
        loading: action.payload.loading,
      };

    default:
      return state;
  }
};

export default rootReducer;
