export const LOADING = 'LOADING';

// User
export const LOGIN = 'LOGIN';
export const REGISTER = 'REGISTER';
export const LOGOUT = 'LOGOUT';
export const EDIT = 'EDIT';
export const CHECK_PASSWORD = 'CHECK_PASSWORD';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const RESET_PASSWORD = 'RESET_PASSWORD';

// Communes
export const SEARCHED_COMMUNES = 'SEARCHED_COMMUNES';
export const SHOW_MODAL_FORM_SENDED_SUCCESSFULLY =
  'SHOW_MODAL_FORM_SENDED_SUCCESSFULLY';
export const GET_ALL_CONSULATES = 'GET_ALL_CONSULATES';
export const GET_ALL_COMMUNES = 'GET_ALL_COMMUNES';
