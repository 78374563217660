import React from 'react';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { CheckPasswordForm } from '../components/forms/CheckPasswordForm';
import { SearchBar } from '../components/SearchBar';
import { SearchResults } from '../components/SearchResults';
import { decryptFn } from '../utils/Functions/functions';
import { Layout } from '../components/common/Layout';

const SearchPage = () => {
  const { paidToken } = useSelector((state) => state);

  const token = decryptFn(paidToken);

  return token ? (
    <Layout>
      <SearchBar />
      <br />
      <SearchResults />
    </Layout>
  ) : (
    <Layout>
      <Container className="d-flex justify-content-center align-items-center h-100 bg-light">
        <CheckPasswordForm />
      </Container>
    </Layout>
  );
};

export default SearchPage;
