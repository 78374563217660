import React from 'react';
import { Footer } from '../Footer';
import { NavBar } from '../NavBar';

export const Layout = ({ children }) => {
  return (
    <div className="d-flex flex-column vh-100 bg-light">
      <NavBar />
      {children}
      <Footer />
    </div>
  );
};
