import React, { useEffect, useState } from 'react';
import { Container, ListGroup, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { CgArrowBottomLeftR } from 'react-icons/cg';
import { MyTooltip } from './common/Tooltip';
import { getInfoCommunesBySearch } from '../redux/actions/communesActions';
import { DetailsCard } from './DetailsCard';
import { MyAlert } from './common/Alert';

export const SearchResults = () => {
  const dispatch = useDispatch();
  const [openKey, setOpenKey] = useState(null);
  const { searchedCommunes } = useSelector((state) => state);

  useEffect(() => {
    if (!searchedCommunes.length) {
      dispatch(
        getInfoCommunesBySearch({ comuna: 'torino', searchBy: 'Provincia' })
      );
    }
  }, []);

  const handleToggle = (key) => {
    setOpenKey(openKey !== key ? key : null);
  };

  const columns = ['Comuna', 'Provincia', 'Demora', 'Extra'];

  return (
    <div className="pb-3 bg-light">
      {searchedCommunes === 'NO DATA' ? (
        <MyAlert
          title="Ninguna comuna coincide con la búsqueda. Por favor, intente nuevamente."
          text="No hay comunas para mostrar"
        />
      ) : (
        <Container
          className="flex-grow-1 overflow-auto pb-5"
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{
            boxShadow: '0px 2px 4px #0000004D',
            borderRadius: '10px',
          }}
        >
          <Row sm={10} xs={10} lg={8}>
            <ListGroup horizontal className="w-100 p-0">
              {columns.map((column) => (
                <ListGroup.Item
                  key={column}
                  className="text-center text-light bg-success w-25"
                >
                  {column}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Row>
          <Row sm={10} xs={10} lg={8}>
            {searchedCommunes.map(
              ({ comuna, provincia, demora_completa, ...content }) => (
                <>
                  <ListGroup key={comuna} horizontal className="w-100 p-0">
                    <ListGroup.Item className="text-center  w-25">
                      {comuna}
                    </ListGroup.Item>
                    <ListGroup.Item className="text-center  w-25">
                      {provincia}
                    </ListGroup.Item>
                    <ListGroup.Item className="text-center  w-25">
                      {demora_completa}
                    </ListGroup.Item>
                    <ListGroup.Item className="text-center  w-25">
                      <MyTooltip title="Click para ver mas">
                        <a
                          className="text-decorator text-success"
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleToggle(comuna)}
                        >
                          <CgArrowBottomLeftR size={20} />
                        </a>
                      </MyTooltip>
                    </ListGroup.Item>
                  </ListGroup>
                  {openKey === comuna && (
                    <Container fluid className="d-block p-0">
                      <DetailsCard content={content} />
                    </Container>
                  )}
                </>
              )
            )}
          </Row>
        </Container>
      )}
    </div>
  );
};
