import React from 'react';
import { Container, ListGroup } from 'react-bootstrap';
import backgroundImageMain from '../assets/fondos/pisa.jpg';
import { RxTriangleRight } from 'react-icons/rx';
import { Layout } from '../components/common/Layout';
import WhoWeAreServices from '../utils/jsons/WhoWeAreServices';

const WhoWeArePage = () => {
  return (
    <Layout>
      <Container
        fluid
        className="flex-grow-1 overflow-auto"
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{
          backgroundImage: `url(${backgroundImageMain})`,
          backgroundSize: 'cover',
        }}
      >
        <Container>
          <h1 className="text-center text-light  p-2">Italy Pass</h1>
          <h5 className="text-center text-light p-1">
            Somos una empresa dedicada a ofrecer servicios relacionados a la
            obtención de la ciudadanía italiana. Brindamos información sobre
            todo el proceso del trámite y todo lo que necesitas saber a la hora
            de emigrar en busca de tu ciudadanía.
          </h5>
        </Container>

        <Container
          className="mx-auto"
          lg={12}
          md={8}
          sm={8}
          xs={8}
          style={{
            gap: '1rem',
            padding: 0,
            margin: 0,
            maxHeight: `60vh`,
            overflowX: 'hidden',
            overflowY: 'auto',
            borderRadius: '0.8rem',
            backgroundColor: '#198754e2',
            boxShadow: '0px 2px 8px #0000004D',
          }}
        >
          <h5 className="text-light p-2">
            Actualmente contamos con una gran variedad de servicios como:
          </h5>
          <ListGroup>
            {WhoWeAreServices.map((item) => (
              <ListGroup.Item key={item}>
                <RxTriangleRight
                  size={20}
                  color="#198754"
                  className="d-flex-row align-self-end pb-1"
                />
                {item}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Container>
      </Container>
    </Layout>
  );
};

export default WhoWeArePage;
