import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Field } from 'formik';

export const Select = ({ label, name, options, type }) => {
  return (
    <Field name={name}>
      {({ field, form }) => {
        const isValid = !form?.errors[field.name];
        const isInvalid = form?.touched[field.name] && !isValid;
        return (
          <Form.Group className="mx-4 mb-lg-3">
            <Form.Label>{label}</Form.Label>
            <Form.Select
              {...field}
              type={type}
              isValid={form.touched[field.name] && isValid}
              isInvalid={isInvalid}
              feedback={form.errors[field.name]}
              as="select"
            >
              <option>Elige una opcion</option>
              {options?.map(({ name, value }, i) => (
                <option key={i} value={value}>
                  {name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        );
      }}
    </Field>
  );
};

Select.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  type: PropTypes.string,
};
