import storage from 'redux-persist/lib/storage';
import {
  CHECK_PASSWORD,
  EDIT,
  LOADING,
  LOGIN,
  LOGIN_FAILED,
  RESET_PASSWORD,
  LOGOUT,
  REGISTER,
  SHOW_MODAL_FORM_SENDED_SUCCESSFULLY,
} from '../../utils/constants/reduxConstants';
import {
  URL_CHANGE_PASSWORD,
  URL_LOGIN,
  URL_FORGOTTEN_PASSWORD,
  URL_LOGOUT,
  URL_REGISTER,
  URL_CHECK_PASSWORD,
} from '../../utils/constants/urlConstants';
import { fetchData } from '../../utils/hooks/fetchData';
import { encryptFn } from '../../utils/Functions/functions';

export const login = (form) => {
  return async (dispatch) => {
    try {
      const res = await fetchData({
        url: URL_LOGIN,
        method: 'POST',
        body: { usuario: form.email.split('@')[0], ...form },
      });
      const data = await res.json();
      const { token, ...userResponse } = data;
      if (res.status === 203) {
        return dispatch({
          type: LOGIN_FAILED,
          payload: { error: 'Usuario o contraseña incorrectos' },
        });
      } else {
        const paidToken = await encryptFn(userResponse.is_paid);
        delete userResponse.is_paid;
        const user = { ...userResponse };
        return dispatch({
          type: LOGIN,
          payload: { token, user, paidToken, error: null },
        });
      }
    } catch (error) {
      throw new Error(error);
    }
  };
};

export const register = (form) => {
  return async (dispatch) => {
    try {
      const res = await fetchData({
        url: URL_REGISTER,
        method: 'POST',
        body: { usuario: form.email.split('@')[0], ...form },
      });
      const data = await res.json();
      const { token, ...userResponse } = data;
      const paidToken = encryptFn(userResponse.is_paid);
      delete userResponse.is_paid;
      const user = { ...userResponse };
      return dispatch({
        type: REGISTER,
        payload: { token, user, paidToken },
      });
    } catch (error) {
      throw new Error(error);
    }
  };
};

export const editProfileUser = (form) => {
  return async (dispatch) => {
    try {
      const res = await fetchData({
        url: URL_CHANGE_PASSWORD,
        method: 'POST',
        body: { usuario: form.email.split('@')[0], ...form },
      });
      const data = await res.json();
      const { token, ...user } = data;
      return dispatch({
        type: EDIT,
        payload: { token, user },
      });
    } catch (error) {
      throw new Error(error);
    }
  };
};

export const confirmForm = (form) => {
  return async (dispatch) => {
    try {
      const res = await fetchData({
        url: URL_CHECK_PASSWORD,
        method: 'POST',
        body: { ...form },
      });

      const paidToken = encryptFn(res.ok);

      return dispatch({
        type: CHECK_PASSWORD,
        payload: paidToken,
      });
    } catch (error) {
      throw new Error(error);
    }
  };
};

export const forgottenPassword = ({ email }) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING,
        payload: true,
      });
      const res = await fetchData({
        url: `${URL_FORGOTTEN_PASSWORD}?email=${email}`,
        method: 'GET',
      });
      dispatch({
        type: SHOW_MODAL_FORM_SENDED_SUCCESSFULLY,
        payload: { showModal: res.ok, loading: false },
      });
    } catch (error) {
      throw new Error(error);
    }
  };
};

export const resetPassword = (form) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING,
        payload: true,
      });
      const res = await fetchData({
        url: URL_FORGOTTEN_PASSWORD,
        method: 'POST',
        body: { ...form },
      });
      return dispatch({
        type: RESET_PASSWORD,
        payload: { showModal: res.ok, loading: false },
      });
    } catch (error) {
      throw new Error(error);
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    try {
      const res = await fetchData({
        url: URL_LOGOUT,
        method: 'POST',
      });
      storage.removeItem('persist:root');
      window.location.href = '/login';
      return dispatch({ type: LOGOUT, payload: res });
    } catch (error) {
      throw new Error(error);
    }
  };
};
