import React from 'react';
import { Badge } from 'react-bootstrap';

export const MyBadge = ({ text, link, data, color, icon }) => (
  <Badge pill bg={color} className="p-1 m-1" style={{ width: 'fit-content' }}>
    {link ? (
      <a target="_blank" rel="noreferrer" className="text-white" href={link}>
        <div className="text-white d-flex align-items-center">
          {icon}
          <span className="text-white mx-1 text-center">{text}</span>
        </div>
      </a>
    ) : (
      data && (
        <div className="text-white d-flex align-items-center">
          {icon}
          <span className="text-white mx-1 text-center">
            {text}: {data}
          </span>
        </div>
      )
    )}
  </Badge>
);
