import React from 'react';
import { Container } from 'react-bootstrap';
import { RotatingLines } from 'react-loader-spinner';

const Loading = () => {
  return (
    <Container
      className="d-flex justify-content-center align-items-center p-4 h-75"
      style={{ borderRadius: '10px' }}
    >
      <RotatingLines
        strokeColor="green"
        strokeWidth="5"
        animationDuration="0.75"
        width="300"
        visible={true}
        ariaLabel="Loading"
        backgroundColor="transparent"
      />
    </Container>
  );
};

export default Loading;
