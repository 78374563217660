import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SHOW_MODAL_FORM_SENDED_SUCCESSFULLY } from '../../utils/constants/reduxConstants';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Loading from './Loading';

export const MyModal = ({ title, text }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showModal, loading } = useSelector((state) => state);

  const handleClose = () => {
    dispatch({
      type: SHOW_MODAL_FORM_SENDED_SUCCESSFULLY,
      payload: { showModal: false, loading: false },
    });
    navigate('/');
  };

  return loading ? (
    <Modal
      show={loading}
      onHide={loading}
      size="lg"
      style={{ backgroundColor: 'transparent' }}
    >
      <Loading />
    </Modal>
  ) : (
    <Modal show={showModal} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title className="text-success">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{text}</Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleClose}>
          Ir al inicio
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
