import * as Yup from 'yup';

const apellidoRequired = 'El apellido es requerido',
  consultaRequired = 'La consulta es requerida',
  contraseñaRequired = 'La contraseña es requerida',
  emailRequired = 'El email es requerido',
  emailValid = 'El email debe ser valido',
  maxApellido = 'El apellido debe tener 20 caracteres como maximo',
  maxConsulta = 'La consulta debe tener 240 caracteres como maximo',
  maxContraseña = 'La contraseña debe tener 15 caracteres como maximo',
  maxNombre = 'El nombre debe tener 20 caracteres como maximo',
  minApellido = 'El apellido debe tener 3 caracteres como minimo',
  minConsulta = 'La consulta debe tener 5 caracteres como minimo',
  minContraseña = 'La contraseña debe tener 6 caracteres como minimo',
  minNombre = 'El nombre debe tener 3 caracteres como minimo',
  nombreRequired = 'El nombre es requerido';

export const loginSchema = Yup.object().shape({
  contraseña: Yup.string()
    .required(contraseñaRequired)
    .max(15, maxContraseña)
    .min(6, minContraseña),
  email: Yup.string().email(emailValid).required(emailRequired),
});

export const registerSchema = Yup.object().shape({
  nombre: Yup.string()
    .min(3, minNombre)
    .max(20, maxNombre)
    .required(nombreRequired),
  apellido: Yup.string()
    .min(3, minApellido)
    .max(20, maxApellido)
    .required(apellidoRequired),
  email: Yup.string().email(emailValid).required(emailRequired),
  contraseña: Yup.string()
    .min(6, minContraseña)
    .max(15, maxContraseña)
    .required(contraseñaRequired),
  confirmarContraseña: Yup.string()
    .required(contraseñaRequired)
    .oneOf([Yup.ref('contraseña'), null], 'Las contraseñas deben ser iguales'),
});

export const forgetPasswordSchema = Yup.object().shape({
  email: Yup.string().email(emailValid).required(emailRequired),
});

export const resetPasswordSchema = Yup.object().shape({
  nuevaContraseña: Yup.string()
    .min(6, minContraseña)
    .max(15, maxContraseña)
    .required(contraseñaRequired),
  confirmarContraseña: Yup.string()
    .required(contraseñaRequired)
    .oneOf(
      [Yup.ref('nuevaContraseña'), null],
      'La nueva contraseña deben ser igual a la confirmacion'
    ),
});

export const editSchema = Yup.object().shape({
  contraseña: Yup.string()
    .min(6, minContraseña)
    .max(15, maxContraseña)
    .required(contraseñaRequired),
  nuevaContraseña: Yup.string()
    .min(6, minContraseña)
    .max(15, maxContraseña)
    .required(contraseñaRequired)
    .notOneOf(
      [Yup.ref('contraseña'), null],
      'La nueva contraseña debe ser diferente a la anterior'
    ),
  confirmarContraseña: Yup.string()
    .required(contraseñaRequired)
    .oneOf(
      [Yup.ref('nuevaContraseña'), null],
      'La nueva contraseña deben ser igual a la confirmacion'
    ),
});

export const checkPasswordSchema = Yup.object().shape({
  contraseña: Yup.string().required(contraseñaRequired),
});

export const searchSchema = Yup.object().shape({
  searchBy: Yup.string()
    .oneOf(['Comuna', 'Provincia', 'Demora'])
    .required(consultaRequired),
  comuna: Yup.string()
    .min(3, minNombre)
    .max(20, maxNombre)
    .required('Este campo es requerido'),
});

export const certificateSchema = Yup.object().shape({
  nombre: Yup.string()
    .min(3, minNombre)
    .max(20, maxNombre)
    .required(nombreRequired),
  email: Yup.string().email(emailValid).required(emailRequired),
  consulta: Yup.string()
    .min(5, minConsulta)
    .max(240, maxConsulta)
    .required(consultaRequired),
});

export const contactSchema = Yup.object().shape({
  nombre: Yup.string()
    .min(3, minNombre)
    .max(20, maxNombre)
    .required(nombreRequired),
  email: Yup.string().email(emailValid).required(emailRequired),
  consulta: Yup.string()
    .min(5, minConsulta)
    .max(240, maxConsulta)
    .required(consultaRequired),
});

export const turnSchema = Yup.object().shape({
  nombre: Yup.string()
    .min(3, minNombre)
    .max(20, maxNombre)
    .required(nombreRequired),
  email: Yup.string().email(emailValid).required(emailRequired),
  consulado: Yup.string().required('El consulado es requerido'),
});
