export const fetchData = async ({
  url,
  method,
  body,
  params = '',
  queryParams = '',
}) => {
  const token = localStorage.getItem('token');
  return await fetch(url, {
    headers: new Headers({
      'Authorization': token ? `Bearer ${token}` : '',
      'Content-Type': 'application/json',
    }),
    method: method.toUpperCase(),
    body: JSON.stringify(body),
    params: JSON.stringify(params),
    queryParams: JSON.stringify(queryParams),
  });
};
