import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { LoginForm } from '../components/forms/LoginForm';
import { RegisterForm } from '../components/forms/RegisterForm';
import { Layout } from '../components/common/Layout';

const LoginPage = () => {
  return (
    <Layout>
      <Row className="d-flex justify-content-center align-items-center flex-grow-1 pb-5">
        <Col
          lg={5}
          md={5}
          sm={10}
          xs={10}
          className="d-flex justify-content-center align-items-center m-0"
        >
          <Container
            className="bg-white m-3"
            fluid
            style={{
              border: '2px dashed green',
              borderRadius: '1rem',
              boxShadow: '0px 2px 8px #0000004D',
            }}
          >
            <h3 className="text-secondary d-flex justify-content-center pt-3">
              Ingresa
            </h3>
            <Row>
              <LoginForm />
            </Row>
          </Container>
        </Col>
        <Col
          lg={5}
          md={5}
          sm={10}
          xs={10}
          className="d-flex justify-content-center align-items-center m-0"
        >
          <Container
            className="bg-white m-3"
            fluid
            style={{
              border: '2px dashed green',
              borderRadius: '10px',
              boxShadow: '0px 2px 8px #0000004D',
            }}
          >
            <h3 className="text-secondary d-flex justify-content-center pt-1">
              Registrate
            </h3>
            <Row>
              <RegisterForm />
            </Row>
          </Container>
        </Col>
      </Row>
    </Layout>
  );
};

export default LoginPage;
