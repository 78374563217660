import React from 'react';
import { Container } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';

export const MyAlert = ({ handleClose, text, title }) =>
  title && (
    <Container>
      <Alert variant="danger" onClose={handleClose} dismissible={handleClose}>
        <Alert.Heading>{title}</Alert.Heading>
        <p>{text}</p>
      </Alert>
    </Container>
  );
