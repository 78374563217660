import React, { useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Input } from '../common/Input';
import { Select } from '../common/Select';
import { turnSchema } from '../../utils/schemas/yupSchemas';
import {
  getAllConsulates,
  postToGetTurns,
} from '../../redux/actions/communesActions';

export const TurnsForm = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state);

  const { allConsulates } = useSelector((state) => state);

  useEffect(() => {
    dispatch(getAllConsulates());
  }, [allConsulates.length]);

  return (
    <Formik
      validationSchema={turnSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        dispatch(postToGetTurns(values));
        resetForm();
        setSubmitting(false);
      }}
      initialValues={{
        nombre: user?.nombre || '',
        email: user?.email || '',
        consulado: '',
      }}
    >
      {({ handleSubmit, values, isSubmitting, isValid }) => (
        <Form noValidate>
          <Input
            label="Nombre"
            placeholder="Nombre"
            name="nombre"
            value={values.nombre}
            disabled={!!user?.email}
          />
          <Input
            label="Email"
            placeholder="E-mail"
            name="email"
            type="email"
            value={values.email}
            disabled={!!user?.email}
          />
          <Select
            label="Consulado"
            options={allConsulates}
            name="consulado"
            value={values.consulado}
          />
          <div className="d-flex justify-content-center align-items-center p-4">
            <Button
              type="click"
              onClick={handleSubmit}
              variant="success"
              style={{ width: 'fit-content' }}
              disabled={!isValid || isSubmitting}
              size="lg"
            >
              Enviar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
