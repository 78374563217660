import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import backgroundImageMain from '../assets/fondos/pisa.jpg';
import { AiOutlineSearch } from 'react-icons/ai';
import { RiComputerLine } from 'react-icons/ri';
import { BiHeadphone } from 'react-icons/bi';
import { IoLocationSharp } from 'react-icons/io5';
import { MdAccessTime } from 'react-icons/md';
import { TbFileSearch } from 'react-icons/tb';
import { Layout } from '../components/common/Layout';

const HomePage = () => {
  const data = [
    [
      {
        name: 'Requisitos de la ciudadania',
        path: '/requisitos',
        icon: <AiOutlineSearch size={80} />,
      },
      {
        name: 'Gestion de turnos',
        path: '/turnos',
        icon: <RiComputerLine size={80} />,
      },
      {
        name: 'Contactanos',
        path: '/contactos',
        icon: <BiHeadphone size={80} />,
      },
    ],
    [
      {
        name: 'Arbol Genealogico',
        path: '',
        icon: <IoLocationSharp size={80} />,
      },
      {
        name: 'Demora de cada comuna',
        path: '/busqueda',
        icon: <MdAccessTime size={80} />,
      },
      {
        name: 'Pedi tus actas italianas',
        path: '/certificados',
        icon: <TbFileSearch size={80} />,
      },
    ],
  ];

  return (
    <Layout>
      <Container
        fluid
        className="flex-grow-1 overflow-auto"
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{
          backgroundImage: `url(${backgroundImageMain})`,
          backgroundSize: 'cover',
        }}
      >
        <Container className="d-flex justify-content-center align-items-center">
          <h1 className="text-center text-light py-5">
            Te ayudamos a tramitar tu ciudadania
          </h1>
        </Container>

        <Container
          className="mx-auto"
          lg={12}
          md={8}
          sm={8}
          xs={8}
          style={{
            gap: '1rem',
            padding: 0,
            margin: 0,
            maxHeight: `60vh`,
            overflowX: 'hidden',
            overflowY: 'auto',
            borderRadius: '0.8rem',
            backgroundColor: '#198754e2',
            boxShadow: '0px 2px 8px #0000004D',
          }}
        >
          {data.map((row, i) => {
            return (
              <Row
                lg={12}
                md={12}
                sm={12}
                xs={12}
                key={row[i].name}
                className="h-50 d-flex justify-content-around"
              >
                {row.map(({ name, path, icon }) => {
                  return (
                    <Col
                      lg={3}
                      md={3}
                      sm={12}
                      xs={12}
                      key={name}
                      className="d-flex align-items-center justify-content-between"
                    >
                      <Link
                        className="w-100 h-100 text-decoration-none"
                        to={path}
                      >
                        <div className="p-4">
                          <div className="d-flex align-items-center justify-content-center text-white">
                            {icon}
                          </div>
                          <h4 className="text-white text-center">{name}</h4>
                        </div>
                      </Link>
                    </Col>
                  );
                })}
              </Row>
            );
          })}
        </Container>
      </Container>
    </Layout>
  );
};

export default HomePage;
