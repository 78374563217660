/* eslint-disable one-var */
// eslint-disable-next-line no-undef
const { REACT_APP_URL_BASE } = process.env;

export const URL_BASE = REACT_APP_URL_BASE;

// User
export const URL_LOGIN = `${URL_BASE}/login`;
export const URL_REGISTER = `${URL_BASE}/register`;
export const URL_LOGOUT = `${URL_BASE}/logout`;
export const URL_CHANGE_PASSWORD = `${URL_BASE}/change_password`;
export const URL_FORGOTTEN_PASSWORD = `${URL_BASE}/forgotten_password`;
export const URL_CHECK_PASSWORD = `${URL_BASE}/check`;

// Communes
export const URL_TO_SEARCH_A_COMMUN = `${URL_BASE}/get_one`;
export const URL_TO_GET_COMMUNES = `${URL_BASE}/comunas`;
export const URL_TO_GET_ALL_CONSULATES = `${URL_BASE}/consulados`;
export const URL_POST_TO_GET_MINUTES = `${URL_BASE}/actas`;
export const URL_POST_TO_CONTACT = `${URL_BASE}/contacto`;
export const URL_POST_TO_GET_TURN = `${URL_BASE}/turnos`;
