import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import React from 'react';

import CertificatesPage from '../pages/CertificatesPage';
import ContactPage from '../pages/ContactPage';
import ForgottenPasswordPage from '../pages/ForgottenPasswordPage';
import HomePage from '../pages/HomePage';
import LoginPage from '../pages/LoginPage';
import ProfilePage from '../pages/ProfilePage';
import RequirementsPage from '../pages/RequirementsPage';
import SearchPage from '../pages/SearchPage';
import TurnsPage from '../pages/TurnsPage';
import FAQPage from '../pages/FAQPage';
import WhoWeArePage from '../pages/WhoWeArePage';

export function RouterWeb() {
  return (
    <Router>
      <Routes>
        <Route element={<HomePage />} exact path="/" />
        <Route element={<CertificatesPage />} path="/certificados" />
        <Route element={<RequirementsPage />} path="/requisitos" />
        <Route element={<SearchPage />} path="/busqueda" />
        <Route element={<ContactPage />} path="/contactos" />
        <Route element={<TurnsPage />} path="/turnos" />
        <Route element={<LoginPage />} path="/login" />
        <Route element={<ProfilePage />} path="/perfil" />
        <Route element={<FAQPage />} path="/FAQ" />
        <Route element={<WhoWeArePage />} path="/quienes_somos" />
        <Route
          element={<ForgottenPasswordPage />}
          path="/recuperar_cuenta/:reset_token"
        />
      </Routes>
    </Router>
  );
}
