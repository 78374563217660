import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { BsFacebook, BsInstagram } from 'react-icons/bs';
import { MdMailOutline } from 'react-icons/md';
import { MyTooltip } from './common/Tooltip';

export const Footer = () => {
  const data = [
    //TODO: cambiar los email path
    {
      name: 'E-mail',
      path: 'mailto:contactos@vivirenitalia.com.ar',
      icon: <MdMailOutline size={20} title="Email" />,
    },
    {
      name: 'Instagram',
      path: 'https://www.instagram.com/italy.pass/',
      icon: <BsInstagram size={20} title="Instagram" />,
    },
    {
      name: 'Facebook',
      path: 'https://www.facebook.com/profile.php?id=100089778281210',
      icon: <BsFacebook size={20} title="Facebook" />,
    },
  ];

  return (
    <Navbar
      bg="dark"
      className="w-100"
      style={{ position: 'fixed', left: 0, bottom: 0 }}
      variant="dark"
    >
      <Container className="w-100">
        <Nav className="me-auto w-100 d-flex justify-content-end">
          {data.map(({ name, path, icon }) => (
            <MyTooltip title={name} key={name}>
              {
                <Nav.Link className="mx-1" href={path} target="_blank">
                  <div className="d-flex">
                    <div className="d-flex align-items-center justify-content-center text-white">
                      {icon}
                    </div>
                    <span className="text-white text-center mx-2">{name}</span>
                  </div>
                </Nav.Link>
              }
            </MyTooltip>
          ))}
        </Nav>
      </Container>
    </Navbar>
  );
};
