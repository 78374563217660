import React from 'react';
import { Accordion, Container } from 'react-bootstrap';
import { Layout } from '../components/common/Layout';
import FAQData from '../utils/jsons/FAQData.json';
import '../styles/styles.css';

const FAQPage = () => {
  return (
    <Layout>
      <Container
        fluid
        className="flex-grow-1 overflow-auto p-3 mb-5 bg-light d-flex justify-content-center"
        lg={12}
        md={12}
        sm={12}
        xs={12}
      >
        <Accordion
          defaultActiveKey={FAQData[0].question}
          className="bg-light w-75 mb-5"
        >
          {FAQData.map(({ question, answer }) => (
            <Accordion.Item eventKey={question} key={question}>
              <Accordion.Header>
                <p className="fw-medium"> {question}</p>
              </Accordion.Header>
              <Accordion.Body>{answer}</Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Container>
    </Layout>
  );
};

export default FAQPage;
