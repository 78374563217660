import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { Input } from '../common/Input';
import { resetPasswordSchema } from '../../utils/schemas/yupSchemas';
import { resetPassword } from '../../redux/actions/userActions';
import { useNavigate, useParams } from 'react-router-dom';

export const ResetPasswordForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { reset_token } = useParams();

  return (
    <Formik
      validationSchema={resetPasswordSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        dispatch(resetPassword({ reset_token, ...values }));
        resetForm();
        setSubmitting(false);
        navigate('/login');
      }}
      initialValues={{
        nuevaContraseña: '',
        confirmarContraseña: '',
      }}
    >
      {({ handleSubmit, values, isSubmitting, isValid }) => (
        <Form noValidate>
          <Input
            label="Nueva Contraseña"
            placeholder="Nueva Contraseña"
            name="nuevaContraseña"
            type="password"
            value={values.nuevaContraseña}
          />
          <Input
            label="Confirmar Contraseña"
            placeholder="Confirmar Contraseña"
            name="confirmarContraseña"
            type="password"
            value={values.confirmarContraseña}
          />
          <div className="d-flex justify-content-center align-items-center p-4">
            <Button
              type="button"
              onClick={handleSubmit}
              variant="success"
              style={{ width: 'fit-content' }}
              disabled={!isValid || isSubmitting}
              size="lg"
            >
              Enviar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
