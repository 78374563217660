import {
  GET_ALL_COMMUNES,
  GET_ALL_CONSULATES,
  LOADING,
  SEARCHED_COMMUNES,
  SHOW_MODAL_FORM_SENDED_SUCCESSFULLY,
} from '../../utils/constants/reduxConstants';
import {
  URL_POST_TO_CONTACT,
  URL_POST_TO_GET_MINUTES,
  URL_POST_TO_GET_TURN,
  URL_TO_GET_ALL_CONSULATES,
  URL_TO_GET_COMMUNES,
  URL_TO_SEARCH_A_COMMUN,
} from '../../utils/constants/urlConstants';
import { fetchData } from '../../utils/hooks/fetchData';

export const getInfoCommunesBySearch = ({ comuna, searchBy }) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING,
        payload: { loading: true, showModal: true },
      });
      const res = await fetchData({
        url: `${URL_TO_SEARCH_A_COMMUN}?query=${comuna}&select=${searchBy}`,
        method: 'GET',
      });
      if (res.status === 200) {
        const data = await res.json();
        return dispatch({
          type: SEARCHED_COMMUNES,
          payload: {
            comunas: data,
            error: null,
            showModal: false,
            loading: false,
          },
        });
      } else {
        return dispatch({
          type: SEARCHED_COMMUNES,
          payload: {
            comunas: 'NO DATA',
            error: 'Niguna comuna coincide con tu busqueda',
            loading: false,
          },
        });
      }
    } catch (error) {
      throw new Error(error);
    }
  };
};

export const getAllCommunes = () => {
  return async (dispatch) => {
    try {
      const res = await fetchData({
        url: URL_TO_GET_COMMUNES,
        method: 'GET',
      });
      const data = await res.json();
      const dataParse = data.map((comuna) => ({
        name: comuna.comuna,
        id: comuna.id,
      }));
      return dispatch({ type: GET_ALL_COMMUNES, payload: dataParse });
    } catch (error) {
      throw new Error(error);
    }
  };
};

export const getAllConsulates = () => {
  return async (dispatch) => {
    try {
      const res = await fetchData({
        url: URL_TO_GET_ALL_CONSULATES,
        method: 'GET',
      });
      const data = await res.json();
      const dataParse = data.map((consulado) => ({
        name: consulado.nombre,
        value: consulado.id,
      }));
      return dispatch({ type: GET_ALL_CONSULATES, payload: dataParse });
    } catch (error) {
      throw new Error(error);
    }
  };
};

export const postToGetMinutes = (form) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING,
        payload: true,
      });
      const res = await fetchData({
        url: URL_POST_TO_GET_MINUTES,
        method: 'POST',
        body: form,
      });
      dispatch({
        type: SHOW_MODAL_FORM_SENDED_SUCCESSFULLY,
        payload: { showModal: res.ok, loading: false },
      });
    } catch (error) {
      throw new Error(error);
    }
  };
};

export const postContact = (form) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING,
        payload: true,
      });
      const res = await fetchData({
        url: URL_POST_TO_CONTACT,
        method: 'POST',
        body: form,
      });
      dispatch({
        type: SHOW_MODAL_FORM_SENDED_SUCCESSFULLY,
        payload: { showModal: res.ok, loading: false },
      });
    } catch (error) {
      throw new Error(error);
    }
  };
};

export const postToGetTurns = (form) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING,
        payload: true,
      });
      const res = await fetchData({
        url: URL_POST_TO_GET_TURN,
        method: 'POST',
        body: form,
      });
      dispatch({
        type: SHOW_MODAL_FORM_SENDED_SUCCESSFULLY,
        payload: { showModal: res.ok, loading: false },
      });
    } catch (error) {
      throw new Error(error);
    }
  };
};
