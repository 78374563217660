import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Formik } from 'formik';
import { Input } from './common/Input';
import { forgetPasswordSchema } from '../utils/schemas/yupSchemas';
import { useDispatch } from 'react-redux';
import { forgottenPassword } from '../redux/actions/userActions';

export const ModalForgetPassword = ({ handleClose, show }) => {
  const dispatch = useDispatch();

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="text-success text-center w-100">
          Ingresá tu E-mail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-primary text-center">
          Se te enviara un correo electronico para restablecer tu contraseña
          luego de completar el formulario.
        </p>
        <Formik
          validationSchema={forgetPasswordSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            dispatch(forgottenPassword(values));
            resetForm();
            setSubmitting(false);
            handleClose();
          }}
          initialValues={{
            email: '',
          }}
        >
          {({ handleSubmit, values, isSubmitting, isValid }) => (
            <>
              <Form noValidate>
                <Input
                  label="Email"
                  placeholder="E-mail"
                  name="email"
                  type="email"
                  value={values.email}
                />
                <div className="d-flex justify-content-center align-items-center p-4">
                  <Button
                    type="submit"
                    onClick={handleSubmit}
                    variant="success"
                    style={{ width: 'fit-content' }}
                    disabled={!isValid || isSubmitting || !values.email}
                    size="lg"
                  >
                    Enviar
                  </Button>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};
