import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Input } from '../common/Input';
import { certificateSchema } from '../../utils/schemas/yupSchemas';
import {
  getAllCommunes,
  postToGetMinutes,
} from '../../redux/actions/communesActions';
import { AutoComplete } from '../common/AutoComplete';

export const CertificateForm = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state);

  const comunas = useSelector((state) => state.allCommunes);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    dispatch(getAllCommunes());
  }, []);

  const handleSubmitDispatch = async (values) => {
    const info = { comuna: selected[0].id, ...values };
    dispatch(postToGetMinutes(info));
  };

  return (
    <Formik
      validationSchema={certificateSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        handleSubmitDispatch(values);
        resetForm();
        setSubmitting(false);
      }}
      initialValues={{
        nombre: user?.nombre || '',
        email: user?.email || '',
        consulta: '',
      }}
    >
      {({ handleSubmit, values, isSubmitting, isValid }) => (
        <Form noValidate>
          <Input
            label="Nombre"
            placeholder="Nombre"
            name="nombre"
            value={values.nombre}
            disabled={!!user?.nombre}
          />
          <Input
            label="Email"
            placeholder="E-mail"
            name="email"
            type="email"
            value={values.email}
            disabled={!!user?.email}
          />
          <Input
            label="Consulta"
            placeholder="Intenta incluir la comuna por la cual estas realizando la consulta"
            name="consulta"
            value={values.consulta}
            textArea
            rows={2}
          />
          <AutoComplete
            label="Comuna"
            options={comunas}
            placeholder="Elije una comuna"
            selected={selected}
            setSelected={setSelected}
          />
          <div className="d-flex justify-content-center align-items-center p-2">
            <Button
              type="submit"
              onClick={handleSubmit}
              variant="success"
              style={{ width: 'fit-content' }}
              disabled={!isValid || isSubmitting}
              size="lg"
            >
              Enviar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
