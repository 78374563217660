import React from 'react';
import { MyBadge } from './common/Badge';
import { HiOutlineMail } from 'react-icons/hi';
import { BsCoin, BsHouse, BsInfoCircle } from 'react-icons/bs';
import { CgSandClock } from 'react-icons/cg';
import { GiWorld } from 'react-icons/gi';
import { IoHammer } from 'react-icons/io5';

export const DetailsCard = ({ content }) => {
  const { link, email, costo, vencimiento, apostilla, alquiler, trabajo } =
    content;
  const data = [
    {
      text: 'Informacion',
      link: link,
      color: 'primary',
      icon: <BsInfoCircle size={15} />,
    },
    {
      text: 'Email',
      link: `mailto:${email}`,
      color: 'info',
      icon: <HiOutlineMail size={15} />,
    },
    {
      text: 'Costo',
      link: costo,
      color: 'warning',
      icon: <BsCoin size={15} />,
    },
    {
      text: 'Vencimiento',
      data: vencimiento,
      color: 'danger',
      icon: <CgSandClock size={15} />,
    },
    {
      text: 'Apostilla',
      data: apostilla,
      color: 'secondary',
      icon: <GiWorld size={15} />,
    },
    {
      text: 'Alquiler',
      link: alquiler,
      color: 'success',
      icon: <BsHouse size={15} />,
    },
    {
      text: 'Trabajo',
      link: trabajo,
      color: 'dark',
      icon: <IoHammer size={15} />,
    },
  ];
  return (
    <div className="d-flex justify-content-around flex-wrap">
      {data.map(({ text, link, data, color, icon }) => (
        <MyBadge
          key={text}
          text={text}
          link={link}
          data={data}
          color={color}
          icon={icon}
        />
      ))}
    </div>
  );
};
