import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './rootReducer';

const persistConfig = {
  key: 'root',
  debug: false,
  storage,
};

/*
 * Uncomment to clean persistor store
 * Storage.removeItem("root:persist");
 */

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
