import React from 'react';
import { Row } from 'react-bootstrap';
import { EditForm } from '../components/forms/EditForm';
import { LayoutForm } from '../components/common/LayoutForms';

const ProfilePage = () => {
  return (
    <LayoutForm>
      <h3 className="text-secondary d-flex justify-content-center pt-3">
        Cambiá tu contraseña
      </h3>
      <Row>
        <EditForm />
      </Row>
    </LayoutForm>
  );
};

export default ProfilePage;
