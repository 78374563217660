import CryptoJS from 'crypto-js';

const paidKey = process.env.REACT_APP_PAID_TOKEN_KEY;

export const encryptFn = (text) => {
  const data = text && CryptoJS.AES.encrypt(text.toString(), paidKey);
  return data.toString();
};

export const decryptFn = (text) => {
  const decrypt = CryptoJS.AES.decrypt(text, paidKey).toString(
    CryptoJS.enc.Utf8
  );
  return Boolean(decrypt);
};
