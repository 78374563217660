import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const MyTooltip = ({ children, title, placement = 'top' }) => {
  const renderTooltip = (props) => <Tooltip {...props}>{title}</Tooltip>;

  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 100, hide: 400 }}
      overlay={renderTooltip}
    >
      {children}
    </OverlayTrigger>
  );
};
