import React from 'react';
import { Layout } from './Layout';
import { Col, Row } from 'react-bootstrap';

export const LayoutForm = ({ children }) => {
  return (
    <Layout>
      <Row className="d-flex justify-content-center align-items-center flex-grow-1 pb-5">
        <Col
          xs={12}
          sm={10}
          md={8}
          lg={6}
          className="p-3 bg-white align-self-center bg-info"
          style={{
            border: '2px dashed green',
            borderRadius: '10px',
            boxShadow: '0px 2px 8px #0000004D',
          }}
        >
          {children}
        </Col>
      </Row>
    </Layout>
  );
};
