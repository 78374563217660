import React from 'react';
import { MyModal } from '../components/common/Modal';
import { ContactForm } from '../components/forms/ContactForm';
import { LayoutForm } from '../components/common/LayoutForms';

const ContactPage = () => {
  return (
    <LayoutForm>
      <h3 className="text-secondary d-flex justify-content-center pt-1">
        ¿Tenés dudas? Contactanos
      </h3>
      <MyModal
        title="Su mensaje ha sido enviado"
        text="Nos contactaremos con usted tan pronto podamos. Muchas gracias por
          utilizar nuestros servicios"
      />
      <ContactForm />
    </LayoutForm>
  );
};

export default ContactPage;
