import React from 'react';
import { Button, Container, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Input } from '../common/Input';
import { checkPasswordSchema } from '../../utils/schemas/yupSchemas';
import { confirmForm } from '../../redux/actions/userActions';

export const CheckPasswordForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state);

  const goToLogin = () => {
    navigate('/login');
  };

  return (
    <Container
      className="p-3 bg-white"
      style={{ border: '2px dashed green', borderRadius: '10px' }}
    >
      <h3 className="text-center p-2">
        Por favor ingrese la clave de pago que se le otorgo
        {!token && ' o inicie sesion'}
      </h3>
      <Row className="d-flex justify-content-center align-items-center p-3">
        <Formik
          validationSchema={checkPasswordSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            dispatch(confirmForm(values));
            resetForm();
            setSubmitting(false);
          }}
          initialValues={{
            contraseña: '',
          }}
        >
          {({ handleSubmit, values, isSubmitting, isValid }) => (
            <Form noValidate className="w-75 p-1">
              <Input
                label="Ingrese la contraseña dada para confirmar su pago"
                placeholder="Contraseña de pago"
                name="contraseña"
                type="password"
                value={values.contraseña}
              />
              <Row
                lg={6}
                md={6}
                sm={6}
                xs={6}
                className="d-flex justify-content-around align-items-center p-1"
              >
                {!token && (
                  <Button
                    type="button"
                    onClick={goToLogin}
                    variant="outline-success"
                    style={{ width: 'fit-content' }}
                    size="lg"
                    className="m-1"
                  >
                    Iniciar sesion
                  </Button>
                )}
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  variant="success"
                  style={{ width: 'fit-content' }}
                  disabled={!isValid || isSubmitting}
                  size="lg"
                  className="m-1"
                >
                  Enviar
                </Button>
              </Row>
            </Form>
          )}
        </Formik>
      </Row>
    </Container>
  );
};
