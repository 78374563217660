import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Image, Nav, Navbar } from 'react-bootstrap';
import { BiLogInCircle, BiUserCircle, BiEdit } from 'react-icons/bi';
import { logout } from '../redux/actions/userActions';
import logo from '../assets/images/logo.png';

export const NavBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state);

  const links = [
    { link: 'Home', path: '/' },
    { link: 'Requisitos', path: '/requisitos' },
    { link: 'Turnos', path: '/turnos' },
    { link: 'Contacto', path: '/contactos' },
    { link: 'Demoras', path: '/busqueda' },
    { link: 'Arbol Genealogico', path: '' },
    { link: 'Actas italianas', path: '/certificados' },
    // { link: 'Quienes somos', path: '/quienes_somos' },
    // { link: 'FAQ', path: '/FAQ' },
  ];

  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  };

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand className="w-25" href="/">
          <Image
            width="50rem"
            height="50rem"
            roundedCircle
            src={logo}
            alt="Logo página"
          />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className="me-auto">
            {links.map(({ link, path }) => (
              <Link
                key={path}
                to={path}
                className="text-decoration-none mx-2 text-light mx-1"
                style={{ minWidth: 'fit-content' }}
              >
                {link}
              </Link>
            ))}
            {token ? (
              <>
                <Link
                  to="/perfil"
                  className="text-light text-decoration-none mx-1"
                  style={{ minWidth: 'fit-content' }}
                >
                  <BiEdit className="mx-1" size={25} />
                  Perfil
                </Link>
                <Link
                  to="/"
                  onClick={handleLogout}
                  className="text-light text-decoration-none mx-1"
                  style={{ minWidth: 'fit-content' }}
                >
                  <BiLogInCircle className="mx-1" size={25} />
                  Cerrar Sesion
                </Link>
              </>
            ) : (
              <Link
                to="/login"
                className="text-light text-decoration-none mx-1"
                style={{ minWidth: 'fit-content' }}
              >
                <BiUserCircle size={28} className="text-success mx-2" />
                Iniciar Sesion
              </Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
