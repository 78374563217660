import React from 'react';
import { Row } from 'react-bootstrap';
import { ResetPasswordForm } from '../components/forms/ResetPasswordForm';
import { LayoutForm } from '../components/common/LayoutForms';

const ForgottenPasswordPage = () => {
  return (
    <LayoutForm>
      <h3 className="text-secondary d-flex justify-content-center pt-3">
        Introduzca su nueva contraseña
      </h3>
      <Row>
        <ResetPasswordForm />
      </Row>
    </LayoutForm>
  );
};

export default ForgottenPasswordPage;
