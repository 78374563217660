import { configureStore } from '@reduxjs/toolkit';

import persistedReducer from '../reducers';

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.PRODUCTION !== 'PRODUCTION',
});

export default store;
