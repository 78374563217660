import { Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

export const AutoComplete = ({
  label,
  placeholder,
  selected,
  setSelected,
  options,
}) => {
  return (
    <Form.Group className="mx-4 mb-3">
      <Form.Label>{label}</Form.Label>
      <Typeahead
        id="basic-typeahead-single"
        labelKey="name"
        onChange={setSelected}
        options={options}
        placeholder={placeholder}
        selected={selected}
      />
    </Form.Group>
  );
};
