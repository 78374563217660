import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Select } from './common/Select';
import { searchSchema } from '../utils/schemas/yupSchemas';
import { getInfoCommunesBySearch } from '../redux/actions/communesActions';
import { Input } from './common/Input';

export const SearchBar = () => {
  const dispatch = useDispatch();

  const options = [
    { name: 'Comuna', value: 'Comuna' },
    { name: 'Provincia', value: 'Provincia' },
    { name: 'Demora', value: 'Demora' },
  ];

  return (
    <Container className="d-flex justify-content-center align-items-center">
      <Row className="w-100">
        <Formik
          initialValues={{
            comuna: '',
            searchBy: '',
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            dispatch(getInfoCommunesBySearch(values));
            resetForm();
            setSubmitting(false);
          }}
          validationSchema={searchSchema}
        >
          {({ handleSubmit, values, isSubmitting, isValid }) => (
            <Form className="w-100 pt-2">
              <Row lg={12} md={12} sm={12}>
                <Col lg={4} md={4} sm={12}>
                  <Select
                    label="Busca por"
                    name="searchBy"
                    options={options}
                    value={values.searchBy}
                  />
                </Col>
                <Col lg={4} md={4} sm={12} className="pt-2">
                  <Input
                    placeholder="Comuna, Provincia o Demora"
                    name="comuna"
                    value={values.comuna}
                  />
                </Col>

                <Col lg={4} md={4} sm={4}>
                  <div className="d-flex justify-content-center pt-4">
                    <Button
                      style={{ width: 'fit-content' }}
                      disabled={!isValid || isSubmitting}
                      onClick={handleSubmit}
                      size="lg"
                      type="submit"
                      variant="success"
                    >
                      Buscar
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Row>
    </Container>
  );
};
