import React from 'react';
import { Container, ListGroup, Tab, Tabs } from 'react-bootstrap';
import '../styles/styles.css';
import { LayoutForm } from '../components/common/LayoutForms';

const RequirementsPage = () => {
  return (
    <LayoutForm>
      <Tabs defaultActiveKey="requisitos" justify className="text-white">
        <Tab eventKey="requisitos" title="Requisitos minimos">
          <Container className="d-flex justify-content-center p-3">
            <ListGroup numbered>
              <ListGroup.Item>
                Tener antepasados Italianos, ya que, para la obtención de la
                ciudadanía Italiana se basan en el principio &lsquo;de
                sangre&lsquo; o &lsquo;Iure sanguinis&lsquo;; por lo tanto solo
                será reconocido ciudadano Italiano todo aquel que pueda
                demostrar su ascendencia con Italianos.
              </ListGroup.Item>
              <ListGroup.Item>
                Contar con toda la documentación probatoria de tu ascendencia
                italiana en línea recta desde tu avo hasta ti.
              </ListGroup.Item>
            </ListGroup>
          </Container>
        </Tab>
        <Tab eventKey="documentacion" title="Documentacion necesaria">
          <SubTab />
        </Tab>
        <Tab eventKey="informacion" title="Información sobre la documentación">
          <Container className="d-flex justify-content-center p-3">
            <p>
              Las actas poseen un tiempo de vigencia de
              <strong> 6 meses en las comunas italianas, </strong> por lo
              general 1 año a 3 años de vigencia en los consulados
              correspondiente por domicilio. (cada Consulado aplica sus reglas)
              Para la presentación de tu carpeta los documentos deben ser{' '}
              <strong> originales y en físico. </strong> En cuanto a las actas
              digitalizadas conviene informarte con la comuna o consulado
              correspondiente, ya que, ellos tienen su propia normativa para
              cada proceso administrativo. Si tu trámite es por
              <strong> vía judicial </strong> materna, las actas pueden llegar a
              tener una vigencia entre <strong> 1 año o 1 año y medio. </strong>
              Las traducciones de los documentos deben ser realizadas por un
              profesional acreditado en un colegio de traductores oficial. Si
              solicitarás tu trámite en el consulado italiano de tu país la
              documentación debe estar rectificada (si hay incoherencias),
              legalizada y traducida
              <strong>
                . Si el trámite lo harás en Italia la documentación debe estar
                rectificada (si hay incoherencias), legalizada, apostillada,
                traducida y con el visto consular
              </strong>
              . Si no posees acta de matrimonio y sentencia de divorcio de algún
              integrante de tu línea de ascendencia, es recomendable
              <strong>
                , comunicate e informate con la comuna italiana o consulado
                correspondiente{' '}
              </strong>
              por domicilio, ellos poseen sus propias normas para realizar el
              trámite.
            </p>
          </Container>
        </Tab>
        <Tab eventKey="tiempos" title="Tiempos">
          <Container className="p-3">
            <h4>¿Cuanto tiempo demora el tramite?</h4>
            <p>
              El tiempo del trámite dependerá del lugar donde decidas hacerlo,
              en una comuna italiana entre
              <strong> 2 a 12 meses </strong> según sea el caso particular, por
              el contrario, en el consulado de tu país el proceso puede llegar a
              tardar algunos años.
              <strong>Es importante, que tomes en cuenta el tiempo </strong>
              que llevará solicitar los documentos, rectificarlos (si es el
              caso), legalizarlos, apostillarlos y traducirlos. En síntesis, el
              tiempo de tu trámite dependerá de <strong> (3) tres </strong>{' '}
              factores fundamentales:
            </p>
            <ListGroup numbered>
              <ListGroup.Item>
                Búsqueda y recolección de documentos.
              </ListGroup.Item>
              <ListGroup.Item>
                Proceso de rectificación (si fuese necesario), legalización,
                apostilla y traducción.
              </ListGroup.Item>
              <ListGroup.Item>
                Procesamiento administrativo de tu carpeta en la comuna o
                consulado respectivo.
              </ListGroup.Item>
            </ListGroup>
          </Container>
        </Tab>
      </Tabs>
    </LayoutForm>
  );
};

const SubTab = () => {
  return (
    <Tabs defaultActiveKey="avo" className="p-2" justify>
      <Tab eventKey="avo" title="Avo">
        <ListGroup>
          <ListGroup.Item className="text-center">
            Acta de nacimiento
          </ListGroup.Item>
          <ListGroup.Item className="text-center">
            Acta de matrimonio (si se casó)
          </ListGroup.Item>
          <ListGroup.Item className="text-center">
            Acta de defuncion
          </ListGroup.Item>
          <ListGroup.Item className="text-center">
            Certificado de NO Naturalización
          </ListGroup.Item>
        </ListGroup>
      </Tab>
      <Tab eventKey="resto" title="Resto de los integrantes">
        <ListGroup>
          <ListGroup.Item className="text-center">
            Acta de nacimiento
          </ListGroup.Item>
          <ListGroup.Item className="text-center">
            Acta de matrimonio (si se casó)
          </ListGroup.Item>
          <ListGroup.Item className="text-center">
            Acta de defuncion (solo si aplica)
          </ListGroup.Item>
        </ListGroup>
      </Tab>
      <Tab eventKey="solicitante" title="Solicitante">
        <ListGroup>
          <ListGroup.Item className="text-center">
            Acta de nacimiento
          </ListGroup.Item>
          <ListGroup.Item className="text-center">
            Acta de matrimonio (si se casó)
          </ListGroup.Item>
          <ListGroup.Item className="text-center">
            Acta de defuncion
          </ListGroup.Item>
          <ListGroup.Item className="text-center">
            Acta de nacimiento de los hijos (si los hay)
          </ListGroup.Item>
        </ListGroup>
      </Tab>
    </Tabs>
  );
};

export default RequirementsPage;
