import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { Input } from '../common/Input';
import { loginSchema } from '../../utils/schemas/yupSchemas';
import { login } from '../../redux/actions/userActions';
import { MyAlert } from '../common/Alert';
import { ModalForgetPassword } from '../ModalForgetPassword';

export const LoginForm = () => {
  const dispatch = useDispatch();
  const { error, token } = useSelector((state) => state);
  const [openAlert, setOpenAlert] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseAlert = async () => {
    setOpenAlert(false);
  };

  return (
    <Formik
      validationSchema={loginSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        dispatch(login(values));
        if (token) {
          setOpenAlert(false);
        } else {
          setOpenAlert(true);
        }
        resetForm();
        setSubmitting(false);
      }}
      initialValues={{
        email: '',
        contraseña: '',
      }}
    >
      {({ handleSubmit, values, isSubmitting, isValid }) => (
        <>
          <Form noValidate>
            <Input
              label="Email"
              placeholder="E-mail"
              name="email"
              type="email"
              value={values.email}
            />
            <Input
              label="Contraseña"
              placeholder="Contraseña"
              name="contraseña"
              type="password"
              value={values.contraseña}
            />
            <div className="d-flex justify-content-center align-items-center p-4">
              <Button
                type="submit"
                onClick={handleSubmit}
                variant="success"
                style={{ width: 'fit-content' }}
                disabled={!isValid || isSubmitting}
                size="lg"
              >
                Enviar
              </Button>
            </div>
            <div className="d-flex justify-content-end" onClick={handleShow}>
              <span
                className="text-success pb-3 text-decoration-underline"
                style={{ cursor: 'pointer' }}
              >
                Olvidé mi contraseña
              </span>
            </div>
          </Form>
          {openAlert ? (
            <MyAlert
              handleClose={handleCloseAlert}
              text={error}
              title={error}
            />
          ) : null}
          <ModalForgetPassword show={show} handleClose={handleClose} />
          {token ? <Navigate relative replace to="/" /> : null}
        </>
      )}
    </Formik>
  );
};
