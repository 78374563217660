import React from 'react';
import { Row } from 'react-bootstrap';
import { MyModal } from '../components/common/Modal';
import { TurnsForm } from '../components/forms/TurnsForm';
import { LayoutForm } from '../components/common/LayoutForms';

const TurnsPage = () => {
  return (
    <LayoutForm>
      <h3 className="text-secondary d-flex justify-content-center">
        Dejanos tus datos para que podamos contactarte
      </h3>
      <MyModal
        title="Su mensaje ha sido enviado"
        text="Nos contactaremos con usted tan pronto podamos. Muchas gracias por
          utilizar nuestros servicios"
      />
      <Row>
        <TurnsForm />
      </Row>
    </LayoutForm>
  );
};

export default TurnsPage;
