import React from 'react';
import { MyModal } from '../components/common/Modal';
import { CertificateForm } from '../components/forms/CertificateForm';
import { LayoutForm } from '../components/common/LayoutForms';

const CertificatesPage = () => {
  return (
    <LayoutForm>
      <h4 className="text-secondary d-flex justify-content-center">
        ¿Necesitas ayuda para buscar tus actas?
      </h4>
      <MyModal
        title="Su mensaje ha sido enviado"
        text="Nos contactaremos con usted tan pronto podamos. Muchas gracias por
          utilizar nuestros servicios"
      />
      <CertificateForm />
    </LayoutForm>
  );
};

export default CertificatesPage;
