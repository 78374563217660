import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Field } from 'formik';

export const Input = ({
  name,
  label,
  type = 'text',
  placeholder,
  textArea,
  rows,
  disabled = false,
}) => {
  return (
    <Field name={name}>
      {({ field, form }) => {
        const isValid = !form?.errors[field.name];
        const isInvalid = form?.touched[field.name] && !isValid;
        return (
          <Form.Group className="mx-4 mb-3">
            <Form.Label>{label}</Form.Label>
            <Form.Control
              {...field}
              type={type}
              placeholder={placeholder}
              as={textArea && 'textarea'}
              rows={rows}
              isValid={form?.touched[field.name] && isValid}
              isInvalid={isInvalid}
              disabled={disabled}
            />
            <Form.Control.Feedback type="invalid">
              {form.errors[field.name]}
            </Form.Control.Feedback>
          </Form.Group>
        );
      }}
    </Field>
  );
};

Input.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  textArea: PropTypes.bool,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
};
